import React, { useEffect, useState } from 'react';
import { Item } from './Item';
import { CircularProgress } from '@material-ui/core';
import { FooterContent } from './Footer';

const Home = ({itemsToDisplay, handleVote}) => {
  const [loading, setLoading] = useState(true)
  const [isClickable, setIsClickable] = useState(true);

  const handleVoteWithClickability = (itemIds) => {
    if (!isClickable) {
      return;
    }
    // Disable clickability
    setIsClickable(false);

    // Enable clickability after 300ms
    setTimeout(() => {
      setIsClickable(true);
    }, 300);

    // Handle the click logic
    handleVote(itemIds);
  }

  useEffect(() => {
    // Load initial value on component mount
    setLoading(false);
  }, []);

  return (
    <div className="content">
      <div className='home-page-text'>
        Vem har bäst vibe?
      </div>
      {loading ?
      <CircularProgress/> :
      <div className='item-pair'>
        <Item
          itemData={itemsToDisplay[0]}
          showParty={false}
          onClick={() => handleVoteWithClickability({"yes": itemsToDisplay[0].id, "no": itemsToDisplay[1].id})}
        />
        <Item
          itemData={itemsToDisplay[1]}
          showParty={false}
          onClick={() => handleVoteWithClickability({"yes": itemsToDisplay[1].id, "no": itemsToDisplay[0].id})}
        />
      </div>}
      <div className="footer">
        <FooterContent/>
      </div>
    </div>
  );
};


export default Home;