import React, { useState } from 'react';
const whiteUrl = "./images/white.jpeg"

export const itemToStr = (params) => {
    let fullName = params.item.firstName + " " + params.item.lastName
    let party = (params.item.party == "" || !params.showParty) ? "" : " (" + params.item.party + ")"
    return fullName + party
}

export const Item = ({itemData, itemKey, rank, showParty, onClick, smallVersion=false}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  var maxWidth = smallVersion ? "60%" : "100%"
  var fontSize = smallVersion ? "18pt" : "30pt"

  return (
  <div className='item-container' key={itemKey}>
    <img 
      src={itemData?.imageUrl ?? whiteUrl}
      onClick={onClick}
      onLoad={handleImageLoad}
      style={{
        maxWidth: maxWidth,
        height: 'auto',
        display: "flex",
        justifySelf: "center"
      }}
    />
    <div className='item-text'
      style={{
        marginTop: "0.5rem",
        marginBottom: "1rem",
        textAlign: "center",
        fontSize: fontSize,
        fontFamily: 'Franklin Gothic', // TODO: Fallback font?
        fontSize: fontSize
        }}>
      {rank !== undefined && `(${rank}) `} 
      {imageLoaded ? itemToStr({ "item": itemData, "showParty": showParty }) : ""}
    </div>
  </div>
  );
};