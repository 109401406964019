import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Ranking from './Ranking';
import ItemsData from './ItemsData';
import WEB_APP_ENDPOINT from './web_app_endpoint';

function AppRouter() {
  const [usedItemsCount, setUsedItemsCount] = useState(2)
  const [items, setItems] = useState([...ItemsData])
  const [itemsToDisplay, setItemsToDisplay] = useState([])
  
  function shuffleList(list) {
    list = list.filter(item => item.firstName !== "")
    const shuffledList = [...list];
  
    for (let i = shuffledList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
  
    return shuffledList;
  }

  function GetHash(year, month, day, hours, minutes) {
    return (year*2*month*11*day) % 37 + (13*hours*17*minutes) + (11*hours) % 17 - (193 * minutes) % 101 + (39*minutes*17*day) % 91 + (year*hours) % minutes
  }

  function GetTimeHash() {
    var date = new Date()
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    var hours = newDate.getHours() + 1;
    var minutes = newDate.getMinutes() + 1;

    return GetHash(year, month, day, hours, minutes)
  }

  function addVote(vote_info) {
    var timeHash = GetTimeHash()

    fetch(WEB_APP_ENDPOINT + "?id_yes=" + vote_info.yes + "&id_no=" + vote_info.no + "&ask=" + timeHash, {
      redirect: "follow",
      method: 'POST',
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.ok) {
        // console.log('Value increased successfully');
      } else {
        console.error('Error increasing value:', response.status);
      }
    })
    .catch((error) => {
      console.error('Error increasing value:', error);
    });
  }

  const handleVote = async (id) => {
    addVote(id)

    let newUsedItemsCount = usedItemsCount + 2
    setUsedItemsCount(newUsedItemsCount)
    if (newUsedItemsCount >= items.length - 1)
    {
      var oldItems = items.filter((i) => !itemsToDisplay.includes(i))
      oldItems = shuffleList(oldItems)
      oldItems.push(itemsToDisplay[0])
      oldItems.push(itemsToDisplay[1])
      setItems(oldItems);
      setUsedItemsCount(2)
    }
    setItemsToDisplay(items.slice(newUsedItemsCount - 2, newUsedItemsCount))
  }

  useEffect(() => 
    {
    let shuffled = shuffleList(items)
    setItems(shuffled)
    setItemsToDisplay(shuffled.slice(0, 2))
    }
  , [])

  return (
    <Router basename={process.env.PUBLIC_URL} style={{ margin: 0 }}>
      <Navbar/>
      <Switch>
        <Route exact path="/" render={() => <Home itemsToDisplay={itemsToDisplay} handleVote={handleVote} />} />
        <Route path="/ranking" component={Ranking}/>
      </Switch>
    </Router>
  );
}

export default AppRouter;
