import React from 'react';
import './App.css';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <AppRouter basename={process.env.PUBLIC_URL}/>
  );
}

export default App;
