import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div style={{ display: "flex" }}>
      <nav className="navbar-left">
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link to="/" className="navbar-link">Rösta</Link>
          </li>
        </ul>
      </nav>
      <nav className="navbar-right">
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link to="/ranking" className="navbar-link">Ranking</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;